import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/BackgroundAsImageWithCenteredContent.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Pricing from "components/pricing/ThreePlans.js";
import Testimonial from "components/testimonials/SimplePrimaryBackground.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/MiniCenteredFooter.js";
import heroScreenshotImageSrc from "images/hero-screenshot-1.png";
import macHeroScreenshotImageSrc from "images/banner/temp.jpg";
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import ContactUs from "./ContactUs";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage disabled>
      <Hero
          roundedHeaderButton={true}
          nav
      />
      <FeatureWithSteps
        subheading={<Subheading>Comment ?</Subheading>}
        heading={
          <>
            Démarrer votre projet <HighlightedText>maintenant !</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc={macHeroScreenshotImageSrc}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />
      <Pricing
        subheading={<Subheading>Nos tarifs</Subheading>}
        heading={
          <>
            Le meilleur au meilleur <HighlightedText>prix.</HighlightedText>
          </>
        }
        description={"Découvrez nos 3 solutions pour devenir visible sur internet sans vous ruiner !"}
        plans={[
          {
            name: "Sur mesure",
            price: "",
            duration: "Un prix tout doux pour démarrer sur le web",
            mainFeature: "La formule idéale pour devenir visible sur internet !",
            features: ["3 sections", "Formulaire de contact", "Site responsive", "Mention légale", "Conforme RGPD"]
          },
          {
            name: "Professionel",
            price: "",
            duration: "Avec cette formule, vous allez décoler !",
            mainFeature: "Dévéloppez votre activité de manière complète.",
            features: ["6 sections", "Référencement Google", "Aide création de contenu", "Webdesign personnalisée", "Sélection & retouche d'images", "Formulaire de contact", "Site responsive", "Mention légale", "Conforme RGPD"],
            featured: true
          },
          {
            name: "E-commerce",
            price: "",
            duration: "La formule idéale pour vendre sur internet.",
            mainFeature: "1,2,3 ... Vendez !",
            features: ["Mode de paiement CB & paypal", "Mondial relay, Colissimo, ...", "Click n' Collect", "Facturation automatique", "Suivi des stocks", "Formation sur votre e-boutique" ]
          }
        ]}
        primaryButtonText={"Choisir"}

      />
      <Testimonial
        subheading={<Subheading></Subheading>}
        heading={
          <>
             Avis client<HighlightedText></HighlightedText>
          </>
        }
        description={"A chaque client son histoire ! Ils nous font confiance et nous ont confier leurs plus beaux projets."}
        testimonials={[
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
            heading: "Super",
            quote:
              "Un grand merci à Lumia Pixel ! Votre expertise nous a permis de créer un site e-commerce à notre image et de réaliser des ventes à travers toute la France !",
            customerName: "Lucile",
            customerProfile: "Maille Baby"
          },
          {
            stars: 4,
            profileImageSrc:
              "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
            heading: "Rapport qualité / prix imbattable",
            quote:
              "Je suis très satisfaite de mon site internet créé par Lumia Pixel. Il correspond à ce que je souhaitais. Avec patience et professionnalisme cette entreprise a répondu à toutes mes demandes. Le résultat est satisfaisant dans un excellent rapport qualité/prix.",
            customerName: "Sophie",
            customerProfile: "Sophrologue"
          }
        ]}

      />
      <GetStarted
          primaryLinkUrl={"contact"}
      />
      <FAQ
        subheading={<Subheading>FAQS</Subheading>}
        heading={
          <>
            Vous avez des <HighlightedText>questions ?</HighlightedText>
          </>
        }
        description={"Voici une liste des questions que vous pourriez vous poser lors de la création de votre site web."}
        faqs={[
          {
            question: "Pourquoi créer mon site internet ?",
            answer:
              "Un site web est une marque de sérieux, il suscite la confiance et rassure vos clients potentiels. Vous pouvez travailler votre visibilité sur internet de manière beaucoup plus complète qu'avec une simple page Facebook par exemple. Les fonctionnalités offertes sont infinies et le graphisme de votre site est en adéquation avec votre identité visuelle."
          },
          {
            question: "A quoi sert le référecement ?",
            answer:
              "Le référencement naturel ou SEO permet de donner de la visibilité à votre site internet sur les moteurs de recherche comme (Google notamment). Le référencement est fondamental car il permettra de générer plus de visite sur votre site web et donc plus de visibilité, de demande de devis ou de commande."
          },
          {
            question: "Qu'est-ce qu'un site responsive ?",
            answer:
              "Le responsive design est une technique de conception qui permet à votre site web de s'adapter à n'importe quelle taille d'écran (smartphone, tablette, ordinateur, ...). Il est primordial que votre site web s'affiche convenablement sur tous les types d'écrans, rappelons au passage que 50% des visites de votre web se feront via un smartphone."
          }
        ]}
      />
      <Footer />
    </AnimationRevealPage>
  );
}
